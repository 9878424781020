import { defineStore } from "pinia";
import { ref } from "vue";
import { useCldURLs } from "~/constants/apiv2.urls";
import { CLD_ENV } from "~~/constants/env";

export const useShippingStore = defineStore("shipping", () => {
  const nuxtApp = useNuxtApp();

  const shippingCost = ref();
  const isShippingCostAdded = ref();

  const CheckPresenceOfShippingCost = async () => {
    const url = useCldURLs[CLD_ENV].shipping.check_shipping_cost;
    const { data } = await useFetchApi(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

      },
    });

    if (data.value) {
      isShippingCostAdded.value = data.value.data;
    }
  };

  const AddShippingCost = async () => {
    const url = useCldURLs[CLD_ENV].shipping.add_shipping_cost;

    const { data } = await useFetchApi(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

      },
    });
    shippingCost.value = data.value.data;
    isShippingCostAdded.value = data.value.data;
  };
  return {
    shippingCost,
    isShippingCostAdded,
    AddShippingCost,
    CheckPresenceOfShippingCost,
  };
});
